import { getNotictMsgList, noticeMsgRead } from '@/api/message.js';
import sleep from '@/utils/sleep';
export default {
  data() {
    return {
      tHeight: 0,
      total: 0,
      tableConfig: {
        page: 1,
        size: 20,
        total: 0,
      },
    };
  },
  created() {
    this.getHeight();
  },
  methods: {
    getHeight() {
      this.tHeight = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },
    //表格高度请求所需要请求的数据size
    async getSourceSize(time) {
      await sleep(time);
      return Math.ceil(parseInt(document.getElementsByClassName('el-table__body-wrapper')[0].style.height) / 51);
    },
    async getNotictMsgList(page, size, msgType = 1, searchContent = '', searchType = '', status = 2) {
      return new Promise(async (resolve, reject) => {
        getNotictMsgList(page, size, msgType, searchContent, searchType, status).then(resolve).catch(reject);
      });
    },
    async noticeMsgRead(params) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await noticeMsgRead(params);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
