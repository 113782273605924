<template>
  <div class="message-table">
    <el-table ref="multipleTable" :data="message" :height="height" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" :selectable="selectable" v-if="!isRead" width="55" align="center"></el-table-column>
      <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
      <el-table-column label="信息编号" prop="msgId" width="100" align="center"></el-table-column>
      <el-table-column label="信息主题" prop="title" width="140" align="center"></el-table-column>
      <el-table-column label="信息模板编号" prop="msgModelId" width="200" align="center"></el-table-column>
      <el-table-column label="信息类型" prop="msgType" width="120" align="center"></el-table-column>
      <el-table-column label="信息状态" prop="status" width="120" align="center">
        <template slot-scope="scope">
          <el-tag size="mini" :type="scope.row.status === 0 ? 'danger' : 'primary'">{{ scope.row.status | messageStatus }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="信息内容" prop="content" show-overflow-tooltip></el-table-column>
      <el-table-column label="通知人编号" prop="noticeTime" width="160" align="center">
        <template slot-scope="scope">
          {{ $moment(scope.row.noticeTime).format('YYYY:MM:DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column label="处理人" prop="noticeUserName" width="140" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="handleConfirm(scope.row)" :disabled="scope.row.status === 0 ? false : true">确认</el-button>
          <!-- <el-button type="text" @click="showMessageDetails(scope.row.msgId)">详情</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :close-on-click-modal="false" title="消息详情" :visible="showMessage">
      <ul>
        <li>
          <strong>信息编号：</strong>
          <span>{{ messageDetails.msgId }}</span>
        </li>
        <li>
          <strong>信息模版编号：</strong>
          <span>{{ messageDetails.msgModelId }}</span>
        </li>
        <li>
          <strong>信息类型：</strong>
          <span>{{ messageDetails.msgType }}</span>
        </li>
        <li>
          <strong>信息编号：</strong>
          <span>{{ messageDetails.msgId }}</span>
        </li>
        <li>
          <strong>信息编号：</strong>
          <span>{{ messageDetails.msgId }}</span>
        </li>
        <li>
          <strong>信息编号：</strong>
          <span>{{ messageDetails.msgId }}</span>
        </li>
        <li>
          <strong>信息编号：</strong>
          <span>{{ messageDetails.msgId }}</span>
        </li>
        <li>
          <strong>信息编号：</strong>
          <span>{{ messageDetails.msgId }}</span>
        </li>
        <li>
          <strong>信息编号：</strong>
          <span>{{ messageDetails.msgId }}</span>
        </li>
      </ul>
    </el-dialog>
  </div>
</template>

<script>
import { noticeMsgDetail } from '@/api/message.js';
import statusInclude from '../../../utils/statusCode';
export default {
  name: 'MessageTable',
  props: {
    message: {
      type: Array,
      default: [],
    },
    height: {
      type: Number,
      default: 0,
    },
    isRead: {
      type: Boolean,
      default: false,
    },
    messageStatus: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      showMessage: false,
      multipleSelection: [],
      messageDetails: {},
    };
  },
  methods: {
    handleConfirm(row) {
      this.$emit('handleConfirm', row.msgId);
    },
    //查看任务详情
    async showMessageDetails(msgId) {
      await this.noticeMsgDetail(msgId);
      this.showMessage = true;
    },
    //缓存选中el
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    submitOptions() {
      return this.multipleSelection;
    },
    //禁用部分选择框
    selectable(row) {
      return row.status == 1 ? false : true;
    },
    //查看任务详情
    async noticeMsgDetail(msgId) {
      const { code, data, reason } = await noticeMsgDetail(msgId);
      if (!statusInclude(code)) this.$message.error(`请求详细信息失败：${reason}`);
      this.messageDetails = data;
      console.log(this.messageDetails);
    },
  },
};
</script>
