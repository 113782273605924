<template>
  <el-row class="pagination-bar">
    <el-pagination background layout="prev, pager, next" :current-page="page" :page-size="size" :total="total" @current-change="handleCurrentChange"></el-pagination>
  </el-row>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
      default: 13,
    },
    total: {
      type: Number,
      default: 100,
    },
  },
  methods: {
    handleCurrentChange(page) {
      this.$emit('handleCurrentChange', page);
    },
  },
};
</script>

<style></style>
