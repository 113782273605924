<template>
  <el-form slot="header" class="search-message-form" size="small" :inline="true">
    <el-form-item label="搜索条件" class="query-type-message">
      <el-input placeholder="请输入内容" v-model="message.searchContent" class="input-with-select">
        <el-select v-model="message.searchType" slot="prepend" placeholder="请选择">
          <el-option label="信息主题" value="title"></el-option>
          <el-option label="信息内容" value="content"></el-option>
        </el-select>
      </el-input>
    </el-form-item>
    <el-form-item label="消息类型">
      <el-select v-model="message.msgType">
        <el-option label="全部类型消息" :value="0"></el-option>
        <el-option label="权限审批" :value="1"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" icon="el-icon-search" @click="handleSearchMessage">搜索</el-button>
    </el-form-item>
    <el-form-item style="float: right" v-if="!isRead">
      <el-button type="primary" :disabled="!hasMessage" @click="handleAllMessage">全部已读(选中)</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'SearchBarMessage',
  props: {
    isRead: {
      type: Boolean,
      default: false,
    },
    hasMessage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: {
        searchType: 'title',
        searchContent: '',
        msgType: 0,
      },
    };
  },
  methods: {
    handleSearchMessage() {
      this.$emit('handleSearchMessage', this.message);
    },
    handleAllMessage() {
      this.$emit('handleAllMessage');
    },
  },
};
</script>

<style lang="scss" scoped>
.search-message-form {
  & .el-form-item {
    .el-button {
      height: 32px;
    }
  }
  & .query-type-message {
    /deep/ .el-form-item__content {
      .el-input-group {
        // display: flex !important;
        box-sizing: border-box;
        // flex-direction: row !important;
      }
      .input-with-select {
        box-sizing: border-box;
        .el-input-group__prepend {
          border: 4px solid var(--main-border-color);

          border-right: none;
          border-radius: 0;
          box-sizing: border-box;

          .el-select {
            height: 20px;
            .el-input {
              .el-input__inner {
                width: 120px !important;
                border-radius: 0;
                border-left: 0;
                border-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
